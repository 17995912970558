<template>
  <div id="mainSearchbar_main" :class="resultSections.length ? 'mainSearchbarShowResults' : ''">
    <div id="mainSearchbar_head">
      <input v-model="searchinput" @click="searchInputChange()" @keyup="searchInputChange()" type="text">
      <i class="fas fa-search"></i>

      {{ resultSectionsLength }}
    </div>
    <div id="mainSearchbar_body" v-if="resultSections.length" v-on-clickaway="searchClickaway">
      <div id="mainSeachbar_bodyContainer">
        <template v-for="(section, index) in resultSections">
          <main-searchbar-result-section :key="section.name" :i="index" :info="section">
            {{ section.name }}
          </main-searchbar-result-section>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MainSearchbarResultSectionObj from "@/assets/scripts/classes/mainSearchbarResultSectionObj";
import MainSearchbarResultSection from "@/components/nav/mainSearchbar/mainSearchbarResultSection";
import { mixin as clickaway } from 'vue-clickaway'
import axios from "axios";
export default {
  mixins: [ clickaway ],
  name: "mainSearchbar",
  components: {MainSearchbarResultSection},
  data(){
    return{
      resultSections: [],
      resultPreload: [],
      searchinput: null,
      casts: [],
    }
  },
  computed: {
    resultSectionsLength(){
      let output = 0;
      for(let key in this.resultSections){
        output += this.resultSections[key].results.length;
      }
      return output;
    }
  },
  methods: {
    searchClickaway(){
      let tempSearchInput = this.searchinput;
      this.searchinput = '';
      this.searchInputChange();
      this.searchinput = tempSearchInput;
    },
    searchInputChange(){
      let output = [];
      if(this.searchinput && this.searchinput.length){
        for(let section in this.resultPreload){
          let filtered = this.resultPreload[section].filter(result => {
            let r = result.name.toLowerCase();
            let s = this.searchinput.toLowerCase();
            if(r.startsWith(s) || r.includes(" " + s)){
              return result
            }
          })
          if(filtered.length){
            output.push(new MainSearchbarResultSectionObj(section, filtered))
          }
        }
      }else{
        output = [];
      }
      this.resultSections = output;
    },
    fetchPreload(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/searches')
      .then(response => {
        this.resultPreload = response.data
      })
    },
    fetchCasts(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/casts')
      .then(response => {
        this.casts = response.data;
        for(let key in  this.resultPreload.casts){
          let rp = this.resultPreload.casts[key];
          let cast = this.casts.filter(x => x.id === rp.id)[0];
          rp['link'] = cast.links[0];
        }
      })
    }
  },
  mounted(){
    this.fetchPreload();
    this.fetchCasts();
    //this.resultSections.push(new MainSearchbarResultSectionObj('videos', []))
    //this.resultSections.push(new MainSearchbarResultSectionObj('genres', []))
  }
}
</script>

<style scoped>
  #mainSearchbar_main{
    --mainSearchbarheight: 40px;
    --mainSearchbarwidth: 400px;

    display: flex;
    height: var(--mainSearchbarheight);
    width: var(--mainSearchbarwidth);
    background-color: white;
    border-radius: calc(var(--mainSearchbarheight) / 2);
    overflow: hidden;
  }
  #mainSearchbar_head{
    --mainSearchbarIconwidth: 40px;

    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
  #mainSearchbar_head>input{
    display: flex;
    height: 100%;
    width: calc(100% - var(--mainSearchbarIconwidth));
    margin: 0;
    padding: 0;
    border: none;
    padding-left: 15px;
    font-size: var(--font-size-12);
  }
  #mainSearchbar_head>input:focus{
    outline: none;
  }
  .mainSearchbarShowResults{
    border-radius: calc(var(--mainSearchbarheight) / 2) calc(var(--mainSearchbarheight) / 2) 0 0!important;
  }
  #mainSearchbar_head>i{
    display: flex;
    height: 100%;
    width: var(--mainSearchbarIconwidth);
    color: var(--color-dark-0);
    justify-content: center;
    align-items: center;
  }
  #mainSearchbar_body{
    display: flex;
    position: absolute;
    top: 50px;
    padding-top: 10px;
    width: var(--mainSearchbarwidth);
    border-left: 1px solid var(--color-dark-0);
    border-right: 1px solid var(--color-dark-0);
    border-bottom: 1px solid var(--color-dark-0);
    background-color: var(--color-light-0);
    color: var(--color-dark-0);
    max-height: 500px;
    margin-left: -1px;
    #border-radius: 0 0 20px 20px;
  }
  #mainSeachbar_bodyContainer{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    padding-bottom: 20px;
  }
</style>
