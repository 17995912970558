<template>
  <div id="layoutBase_main">
    <div id="layoutBase_top">
      <top-navigation></top-navigation>
    </div>
    <div id="layoutBase_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TopNavigation from "@/components/nav/topNavigation";
export default {
  name: "layoutBase",
  components: {TopNavigation}
}
</script>

<style scoped>
  #layoutBase_main{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    --layoutBase_top_height: 60px;
  }
  #layoutBase_top{
    display: flex;
    height: var(--layoutBase_top_height);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 200;
  }
  #layoutBase_content{
    display: flex;
    margin-top: var(--layoutBase_top_height);
    height: calc(100% - var(--layoutBase_top_height));
    width: 100%;
    --layoutBase_content_width: 1500px;
    flex-direction: column;
    align-items: center;
  }
</style>
