<template>
  <div id="homebannerDisplay_main">
    <div id="homebannerDisplay_grid">
      <template>
        <video-display :mode="setDisplayMode(video.id)" :key="video.id"  :video="video" v-for="(video, index) in processedVideos" :class="'homebannerDisplay_gridVideo_' + index"></video-display>
      </template>
    </div>
  </div>
</template>

<script>
import VideoDisplay from "@/components/generalComponents/videoDisplay";
export default {
  name: "homeBannerDisplay",
  props: ['videos', 'liveVideo'],
  components: {VideoDisplay},
  data(){
    return{

    }
  },
  computed: {
    processedVideos(){
      let output = null;

      output = this.videos;
      if(this.liveVideo){
        output[1] = this.liveVideo
      }

      return output;
    }
  },
  methods: {
    setDisplayMode(vID){
      let output = 'full';

      if(this.liveVideo && vID === this.liveVideo.id){
        output = 'live';
      }

      return output;
    }
  }
}
</script>

<style scoped>
  #homebannerDisplay_main{
    display: flex;
    height: 622px;
    width: 100%;
    background-color: var(--color-dark-1);
    justify-content: center;
    align-items: center;
  }
  #homebannerDisplay_grid{
    display: grid;
    height: calc(100% - 60px);
    width: var(--layoutBase_content_width);
    grid-template-areas:
      "one one two"
      "one one three";
    grid-gap: 5px;
  }
  #homebannerDisplay_grid>*{
    border-radius: 5px;
  }
  .homebannerDisplay_gridVideo_1{
    grid-area: one;
  }
  .homebannerDisplay_gridVideo_2{
    grid-area: two;
  }
  .homebannerDisplay_gridVideo_3{
    grid-area: three;
  }
</style>
