<template>
  <div id="watchSection_main">
    <div id="watchSection_title">
      <slot></slot>
    </div>
    <div id="watchSection_content">
      <video-display mode="sectionVertical" :key="video" v-for="video in 10"></video-display>
    </div>
  </div>
</template>

<script>
import VideoDisplay from "@/components/generalComponents/videoDisplay";
export default {
name: "watchSection",
  components: {VideoDisplay}
}
</script>

<style scoped>

</style>
