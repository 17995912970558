<template>
  <layout-base>
    <div id="lf_main" class="blacked" :style="!casts ? 'height: 100%' : ''">
      <template v-if="casts" >
        <router-link :to="'/channel?c=' + cast.id"  id="lf_cast" v-for="cast in brandCasts" :key="cast.id">
          <div id="lf_cast_box">
            <img v-if="cast.links.length" :src="cast.links[0].link" alt="">
          </div>
          <span>{{ cast.name }}</span>
        </router-link>
      </template>
      <loading v-else></loading>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/layouts/layoutBase";
import axios from "axios";
import Loading from "@/components/generalComponents/loading";
export default {
  name: "Lf",
  components: {Loading, LayoutBase},
  data(){
    return{
      casts: null,
      brandCasts: null,
    }
  },
  methods: {
    fetchCasts(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/casts')
      .then(response => {
        this.casts = response.data;
        let castsWithIR = this.casts.filter(x => x.intendedroles.length);
        this.brandCasts = castsWithIR.filter(x => x.intendedroles[0].id == process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID);
      })
    }
  },
  mounted() {
    this.fetchCasts();
  }
}
</script>

<style scoped>
  html,
  body{
    background-color: var(--color-dark-0);
  }
  #lf_main{
    display: grid;
    width: 100%;
    justify-content: center;
    grid-gap: 10px 15px;

    grid-template-columns: repeat(auto-fit, 200px);
    grid-template-rows: repeat(auto-fit, 130px);
    background-color: var(--color-dark-1);
  }
  #lf_cast{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: var(--color-dark-0);
    padding: 5px;
  }
  #lf_cast_box{
    display: flex;
    height: 100px;
  }
  #lf_cast_box>img{
    width: 100px;
  }
</style>
