<template>
  <router-link :class="'videoDisplay_main_' + mode" id="videoDisplay_main" :to="'watch?v=' + video.id">
    <template v-if="mode === 'full'">
      <div id="videoDisplay_videoBox_full">
        <div id="videoDisplay_background" :style="'background-image: url(' + joatApiUrl + video.thumbnails[0].joatlink || 'https://th.bing.com/th/id/R9dc4203de7a0ec517cf1a39104c45091?rik=cwAMNcoKuqMG1Q&riu=http%3a%2f%2fwww.krafthand.de%2fuploads%2fpics%2fLogo_COPARTS_blau_.jpg&ehk=i23RnhlB%2b7KALwMEZMaR1k9BusACOzL5arMlbPIudK4%3d&risl=&pid=ImgRaw' + ');'">
          <div id="videoDisplay_main_full_info" class="general_overflow-dots">
            <p :title="video.name">{{ video.name }}</p>
          </div>
          <div id="videoDisplay_length">{{ video.length || '00:00' }}</div>
        </div>
      </div>
    </template>
    <template v-else-if="mode === 'section'">
      <div id="videoDisplay_videoBox_section">
        <div id="videoDisplay_background" :style="'background-image: url(' + joatApiUrl + video.thumbnails[0].joatlink || 'https://th.bing.com/th/id/R9dc4203de7a0ec517cf1a39104c45091?rik=cwAMNcoKuqMG1Q&riu=http%3a%2f%2fwww.krafthand.de%2fuploads%2fpics%2fLogo_COPARTS_blau_.jpg&ehk=i23RnhlB%2b7KALwMEZMaR1k9BusACOzL5arMlbPIudK4%3d&risl=&pid=ImgRaw' + ');'">
          <div id="videoDisplay_length">{{ video.length || '00:00' }}</div>
        </div>
      </div>
      <div id="videoDisplay_main_section_info" class="general_overflow-dots" :class="blackText ? 'videoDisplay_main_section_info_black' : ''">
        <span :title="video.name">{{ video.name }}</span>
      </div>
    </template>
    <template v-else-if="mode === 'live'">
      <div id="videoDisplay_videoBox_live">
        <video-preview :startTime="randomInRange(20, 60)" :newOptions="liveVideoOptions" id="videoDisplay_videoBox_video" :video="video"></video-preview>
        <div id="videoDisplay_videoBox_liveLabel">
          <circle-pulse></circle-pulse>
          <span>LIVE</span>
        </div>
      </div>
    </template>
  </router-link>
</template>

<script>
import VideoPreview from "@/components/generalComponents/videoPreview";
import CirclePulse from "@/components/generalComponents/circlePulse";
export default {
  name: "videoDisplay",
  components: {CirclePulse, VideoPreview},
  props: ['mode', 'video', 'blackText'],
  data(){
    return{
      joatApiUrl: process.env.VUE_APP_JOAT_KV_API_URL,
      liveVideoOptions: {
        autoplay: true,
            controls: false,
            muted: false,
            loop: true,
            sources: [{
              src: process.env.VUE_APP_JOAT_KV_API_URL + this.video.joatlink
            }]
      },
    }
  },
  methods: {
    randomInRange(min, max){
      const r = Math.random()*(max-min) + min
      return Math.floor(r)
    }
  }
}
</script>

<style scoped>
  #videoDisplay_main{
    display: flex;
    text-decoration: none!important;
  }
  .videoDisplay_main_full{
    height: 100%;
    width: 100%;
  }
  .videoDisplay_main_section{
    width: 177px;
    flex-direction: column;
    margin-right: 20px;
  }
  #videoDisplay_videoBox_section{
    display: flex;
    height: 100px;
    width: 177px;
    border-radius: 5px;
    overflow: hidden;
  }
  #videoDisplay_videoBox_live{
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  #videoDisplay_videoBox_full{
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
  #videoDisplay_background{
    display: flex;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-origin: border-box;
    position: relative;
  }
  #videoDisplay_length{
    display: flex;
    background-color: rgba(0,0,0,0.5);
    color: var(--color-light-0);
    position: absolute;
    bottom: 5px;
    right: 5px;
    padding: 2px 5px 2px 5px;
    font-size: var(--font-size-8);
  }
  #videoDisplay_main_section_info{
    font-size: var(--font-size-10);
  }
  #videoDisplay_main_section_info>span{
    color: var(--color-light-0);
  }
  .videoDisplay_main_section_info_black>span{
    color: var(--color-dark-0)!important;
  }
  #videoDisplay_main_full_info{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 9%, rgba(0,0,0,0) 29%, rgba(0,0,0,0) 100%);
    flex-direction: column-reverse;
    color: var(--color-light-0);
  }
  #videoDisplay_main_full_info>p{
    padding: 20px;
    margin: 0;
    font-size: var(--font-size-14);
  }
  #videoDisplay_videoBox_video{
    --videoPreview-width: 100%;
    display: flex;
    width: var(--videoPreview-width);
    #height: calc(var(--videoPreview-width) * 9 / 16);
  }
  #videoDisplay_videoBox_liveLabel{
    display: flex;
    position: absolute;
    top: 15px;
    right: 20px;
    align-items: center;
    #background-color: rgba(0, 0, 0, 0.3);
    padding-left: 10px;
    padding-right: 10px;
  }
  #videoDisplay_videoBox_liveLabel>span{
    color: var(--color-light-0);
    font-size: var(--font-size-14);
  }
</style>
