<template>
  <div id="homeSection_main">
    <div id="homeSection_title">
      <slot></slot>
    </div>
    <div v-show="videos" class="homeSection_content_scrollButton_right" @click="scrollContent(-500)">
      <i class="fas fa-chevron-right"></i>
    </div>
    <div class="homeSection_content_scrollButton_left" @click="scrollContent(500)">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div id="homeSection_content" ref="homeSection_content">
      <img id="homeSection_contentBanner" v-if="withBanner" :src="getJoatLink(genre, 'main')" alt="">
      <template v-for="video in videos.slice().reverse()">
        <video-display :blackText="bg === 'white'" v-if="!video.blacklisted" mode="section" :key="video.id" :video="video"></video-display>
      </template>
    </div>
  </div>
</template>

<script>
import VideoDisplay from "@/components/generalComponents/videoDisplay";
export default {
  name: "homeSection",
  components: {VideoDisplay},
  props: ['videos', 'genre', 'withBanner', 'bg'],
  methods: {
    scrollContent(scroll){
      console.log(this.$refs.homeSection_content)
      this.$refs.homeSection_content.scrollLeft += -scroll
    },
    getJoatLink(genre, type){
      let thumbnails = genre.thumbnails;
      console.log(thumbnails);
      let right = thumbnails.filter(x => x.joatlink.includes(type))[0];
      return process.env.VUE_APP_JOAT_KV_API_URL + right.joatlink;
    }
  }
}
</script>

<style scoped>
  #homeSection_main{
    display: flex;
    width: var(--layoutBase_content_width);
    height: 220px;
    flex-direction: column;
    justify-content: space-between;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    position: relative;
  }
    #homeSection_title{
    display: flex;
    height: 20px;
    width: 200px;
    margin-top: 20px;
    font-weight: bold;
    font-size: var(--font-size-12);
  }
  #homeSection_content{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  #homeSection_content::-webkit-scrollbar {
    display: none;
  }
  .homeSection_content_scrollButton_left,
  .homeSection_content_scrollButton_right{
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--color-light-0);
    color: var(--color-dark-2);
    z-index: 150;
    border: 1px solid var(--color-light-1);
    top: 100px;
  }
  .homeSection_content_scrollButton_left{
    left: -20px;
  }
  .homeSection_content_scrollButton_right{
    right: -20px;
  }
  #homeSection_contentBanner{
    display: flex;
    margin-right: 10px;
    /*max-height: calc(100% - 20px);*/
    max-height: 140px;
    max-width: 250px;
  }
</style>
