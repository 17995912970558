<template>
  <div id="mainSearchbarResultSection_main">
    <div v-if="i" class="general_hr"></div>
    <span id="mainSearchbarResultSection_head">
      <slot></slot>
    </span>
    <span @click="clickSearchItem(result)" class="mainSearchbarResultSection_item"  v-for="result in info.results" :key="JSON.stringify(result)">
      <img class="mainSearchbarResultSection_item_img" v-if="result.link" :src="result.link.link" alt="">
      {{ result.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "mainSearchbarResultSection",
  props: ["info", "i"],
  methods: {
    clickSearchItem(item){
      if(this.info.name === "videos"){
        this.$router.push({ name: 'Watch', query: { v: item.id } });
      }else if(this.info.name === "casts"){
        this.$router.push({ name: 'Channel', query: { c: item.id } });
      }else if(this.info.name === "genres"){
        this.$router.push({ name: 'Channel', query: { g: item.id } });
      }
    }
  }
}
</script>

<style scoped>
  #mainSearchbarResultSection_main{
    display: flex;
    flex-direction: column;
  }
  #mainSearchbarResultSection_head{
    color: var(--color-dark-2);
    font-weight: bold;
    font-size: var(--font-size-10);
  }
  .mainSearchbarResultSection_item{
    color: var(--color-dark-0);
    font-size: var(--font-size-12);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }
  .mainSearchbarResultSection_item:hover{
    background-color: var(--color-light-1);
  }
  .mainSearchbarResultSection_item_img{
    max-height: 20px;
    max-width: 20px;
  }
</style>
