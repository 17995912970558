<template>
    <div class="">
      Please wait ...
    </div>
</template>
<script>

//import axios from 'axios'

export default {
  name: "Callback",
  props:[
    'code'
  ],

  data () {
    return {
        res:false
    }

  },
  computed:{

  },
  methods:{


  },
  mounted(){

    this.$dmAuth.callback(this.$route.query.code).then((response) => {

      if(response==true) {
        this.$router.push({name:'Home'})
      } else {
        this.$router.push({name:'Home'})
      }

    }, (error)=> {
      console.log(error)
      this.$router.push({name:'Home'})
    })
  }
}

</script>
