<template>
  <layout-base>
    <div id="upload_main">

    </div>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/layouts/layoutBase";
export default {
name: "Upload",
  components: {LayoutBase}
}
</script>

<style scoped>

</style>
