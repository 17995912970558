<template>
  <layout-base>
    <div id="watch_main">
      <div id="watch_grid">
        <watch-video v-if="video" :video="video" :startTime="startTime"></watch-video>
        <watch-section v-if="false"></watch-section>
      </div>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/layouts/layoutBase";
import WatchVideo from "@/components/watchComponents/watchVideo";
import WatchSection from "@/components/watchComponents/watchSection";

import axios from 'axios';

export default {
  name: "Watch",
  components: {WatchSection, WatchVideo, LayoutBase},
  data(){
    return{
      video: null,
      requestedVideo: this.$route.query['v'],
      startTime: this.$route.query['t'] | 0,
      fullvideos: null,
      relatedVideos: null,
    }
  },
  watch: {

  },
  methods: {
    fetch(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/videos/' + this.requestedVideo)
          .then(response => {
            this.video = response.data;
          })
    },
  },
  mounted(){
    this.fetch();
  }
}
</script>

<style scoped>
  #watch_main{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  #watch_grid {
    display: flex;
    width: var(--layoutBase_content_width);
    flex-direction: column;
  }
</style>
