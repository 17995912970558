<template>
    <router-view :key="$route.fullPath"></router-view>
</template>

<style>
  html,
  body{
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  body{
    overflow-y: scroll;
  }
</style>
<script>
export default {
  mounted() {
     //window.jwplayer.key='vcNVSfo9SV38aRtbuOt2GEHC1yoVYYcMRnFvyA=='
    if (window.document.domain === "mediathek.shop.profi-service-digital.de") {
      window.document.domain = "shop.profi-service-digital.de";
      window.parent.dispatchEvent(new CustomEvent("iframeReady"));
    }
  }
}
</script>
