<template>
  <layout-base>
    <div id="edit_header" v-if="video">
      <div id="edit_headerTitle">
        <i class="fas fa-pen"></i>
        Videoinformationen
      </div>
      <button :disabled="videoTempChanges" id="edit_headerButton" @click="saveEdit()" class="general_button">
        <span v-if="!loading">Speichern</span>
        <loading v-else></loading>
      </button>
    </div>
    <div id="edit_main" v-if="video" :class="loading ? 'loading' : ''">
      <div id="edit_mainDataBox">
        <info-label class="edit-info-label" label="Name">
          <input type="text" v-model="videoTemp.name">
        </info-label>
        <info-label class="edit-info-label" label="Beschreibung">
          <textarea v-model="videoTemp.description"></textarea>
        </info-label>
        <info-label class="edit-info-label" label="Produkte (Art.Nr kommasepariert)">
          <textarea style="max-height: 50px!important" v-model="products"></textarea>
        </info-label>
        <info-label class="edit-info-label" label="Producer">
          <multiselect v-if="selects.producer.possible" v-model="selects.producer.selected" :options="selects.producer.possible" label="name"></multiselect>
        </info-label>
        <info-label class="edit-info-label" label="Marke">
          <multiselect v-if="selects.brand.possible.length" v-model="selects.brand.selected" :options="selects.brand.possible" :multiple="true" :searchable="true" label="name" track-by="id"></multiselect>
        </info-label>
        <info-label class="edit-info-label" label="Genre">
          <multiselect v-if="selects.genre.possible" v-model="selects.genre.selected" :options="selects.genre.possible" :searchable="true" label="name"></multiselect>
        </info-label>
        <div id="edit_main_dates">
          <info-label class="edit-info-label" label="Veröffentlicht am">
            <date-picker value-type="format" :lang="datePickerLang" v-model="videoTemp.published_at" :input-class="'edit_main_datesPicker'" :show-second="false" type="datetime" :minute-step="5"></date-picker>
          </info-label>
          <info-label class="edit-info-label" label="Gültig Von - Bis">
            <date-picker value-type="format" :lang="datePickerLang" v-model="videoTemp.to_publish_at" range :input-class="'edit_main_datesPicker'" :show-second="false" type="datetime" :minute-step="5"></date-picker>
          </info-label>
        </div>
      </div>

      <div id="edit_mainBox_videoBox">
        <video-preview id="edit_mainBox_videoPreview" :video="video"></video-preview>
        <info-label id="edit_mainBox_videoBox_videolink" label="Video Link">
          <router-link :to="'watch?v=' + requestedVideo">
            {{ currentUrl + '/watch?v=' + requestedVideo }}
          </router-link>
        </info-label>
        <info-label id="edit_mainBox_videoBox_filename" label="Dateiname">
          <a :href="joatlink + video.joatlink" id="edit_mainBox_videoPreview_filename">
            {{ video.joatlink.split('/')[video.joatlink.split('/').length-1] }}
          </a>
        </info-label>
        <info-label id="edit_mainBox_videoBox_uploadedAt" label="Hochgeladen am">
          <span id="edit_mainBox_videoPreview_uploadedAt">
            {{ video.uploaded_at }}
          </span>
        </info-label>
        <info-label id="edit_mainBox_videoBox_blacklisted" label="blacklisted">
            <input v-model="videoTemp.blacklisted" type="checkbox">
        </info-label>
        <info-label id="edit_mainBox_videoBox_vote" label="top video">
            <select v-model="vote">
              <option value="0">kein</option>
              <option value="1">Top 1</option>
              <option value="2">Top 2</option>
              <option value="3">Top 3</option>
            </select>
        </info-label>
      </div>
    </div>
  </layout-base>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import de from 'vue2-datepicker/locale/de'
import 'vue2-datepicker/index.css';
import LayoutBase from "@/components/layouts/layoutBase";
import videojs from "video.js";
import VideoPreview from "@/components/generalComponents/videoPreview";
import InfoLabel from "@/components/generalComponents/infoLabel";
import Loading from "@/components/generalComponents/loading";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
name: "Edit",
  components: {Loading, InfoLabel, VideoPreview, LayoutBase, DatePicker, Multiselect},
  props: [],
  data(){
    return{
      video: null,
      loading: false,
      currentUrl: window.location.origin,
      requestedVideo: this.$route.query['v'],
      videoTemp: null,
      videoTempCompare: null,
      apis: {
        producers: process.env.VUE_APP_HOLODECK_API_URL + "/producers",
        brands: process.env.VUE_APP_HOLODECK_API_URL + "/cast-castrole",
        products: process.env.VUE_APP_HOLODECK_API_URL + "/cast-video",
        genres: process.env.VUE_APP_HOLODECK_API_URL + "/genres",
      },
      datePickerLang: de,
      products: null,
      joatlink: process.env.VUE_APP_JOAT_KV_API_URL,
      hasProductCast: false,
      selects: {
        producer: {
          selected: [],
          possible: null
        },
        brand: {
          selected: [],
          possible: []
        },
        genre: {
          selected: [],
          possible: null
        }
      },
      success: {
        video: false,
        products: false,
        brands: false,
        genres: false,
        votes: false,
      },
      vote: 0,
      vote_id: null,
    }
  },
  computed: {
    videoTempChanges(){
      for(let key in this.videoTemp){
        if(this.videoTemp[key] !== this.videoTempCompare[key]){
          console.log(this.videoTemp[key] === this.videoTempCompare[key])
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    fetchVideo(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/videos/' + this.requestedVideo, {headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }})
          .then(response => {
            this.video = response.data;
            this.videoTemp = {
              name: response.data.name,
              description: response.data.description,
              producer_id: response.data.producer_id,
              published_at: response.data.published_at,
              to_publish_at: [],
              to_end_at: response.data.to_end_at,
              joatlink: response.data.joatlink,
              tagline: response.data.tagline,
              length: response.data.length,
              maxresolution: response.data.maxresolution,
              maxfov: response.data.maxfov,
              language_id: response.data.language_id,
              country_id: response.data.country_id,
              channel_id: response.data.channel_id,
              uploader_id: response.data.uploader_id,
              parentalguidance_id: response.data.parentalguidance_id,
              uploaded_at: response.data.uploaded_at,
              blacklisted: response.data.blacklisted,
              vote: response.data.votes
            };
            this.videoTemp.to_publish_at[0] = this.video.to_publish_at;
            this.videoTemp.to_publish_at[1] = this.video.to_end_at;

            this.vote = this.videoTemp.vote[0].grade;
            this.vote_id = this.videoTemp.vote[0].id;

            this.videoTempCompare = JSON.parse(JSON.stringify(this.videoTemp));

            this.fetchProducts();
            this.fetchProducers();
            this.fetchBrands();
            this.fetchGenres();
          })
    },
    fetchProducts(){
      axios.get(this.apis.products)
      .then(response => {
        let res = this.getfetchedData(response.data);
        let myRelations = res.filter(x => x.video_id === this.video.id);
        let products = myRelations.filter(r => r.castrole_id == process.env.VUE_APP_HOLODECK_API_CASTROLE_MERKARTORPRODUCT_ID);
        this.products = products[0].cast.name.replace(/"/g,'');
        this.hasProductCast = products[0].id;
      })
    },
    fetchProducers(){
      axios.get(this.apis.producers)
      .then(response => {
        this.selects.producer.possible = this.getfetchedData(response.data);
        this.selects.producer.selected = this.selects.producer.possible.find(x => x.id == this.videoTemp.producer_id)
      })
    },
    fetchBrands(){
      axios.get(this.apis.brands)
      .then(response => {
        let res = this.getfetchedData(response.data);
        console.log(res);
        let brands = res.filter(x => x.castrole_id == process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID);
        for(let castKey in brands){
          this.selects.brand.possible.push(brands[castKey].cast);
        }
        let videoBrands = [];
        for(let cast in this.video.casts){
          let c = this.video.casts[cast];
          if(c.intendedroles.length && c.intendedroles.filter(x => x.id == process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID)){
            videoBrands.push(c);
          }
        }
        this.selects.brand.selected = videoBrands;
      });
    },
    fetchGenres(){
      axios.get(this.apis.genres)
      .then(response => {
        this.selects.genre.possible = this.getfetchedData(response.data);
        this.selects.genre.selected = (this.selects.genre.possible.find(x => x.id == this.video.genres[0].id))
      })
    },
    getfetchedData(data){
      return data.data ? data.data : data
    },
    saveEdit(){
      this.loading = true;
      this.putVideoInfo();
      this.putGenres();
      this.putProducts();
      this.putBrands();
      this.putVote();
    },
    putGenres(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + "/genre-video")
          .then(response => {
            let videoRelations = this.getfetchedData(response.data).filter(x => x.video_id === this.video.id);
            if (videoRelations.length) {
              let delCount = 0;
              for (let rel in videoRelations) {
                axios.delete(process.env.VUE_APP_HOLODECK_API_URL + "/genre-video/" + videoRelations[rel].id)
                    .then(() => {
                      delCount++;
                      if (delCount === videoRelations.length) {
                        axios.post(process.env.VUE_APP_HOLODECK_API_URL + "/genre-video", {
                          genre_id: this.selects.genre.selected.id,
                          video_id: this.video.id
                        })
                            .then(() => {
                              this.success.genres = true;
                              this.allSuccessfullCheck();
                            })
                      }
                    })
              }
            } else {
              axios.post(process.env.VUE_APP_HOLODECK_API_URL + "/genre-video", {
                genre_id: this.selects.genre.selected.id,
                video_id: this.video.id
              })
                  .then(() => {
                    this.success.genres = true;
                    this.allSuccessfullCheck();
                  })
            }
          })
    },
    putBrands(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + "/cast-video")
      .then(response => {
        let res = this.getfetchedData(response);
        let relations = res.filter(x => x.video_id === this.video.id);
        let brands = relations.filter(x => x.castrole_id == process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID);
        let newIds = [];
        for(let k in this.selects.brand.selected){
          newIds.push(this.selects.brand.selected[k].id)
        }
        if(brands.length){
          let delCount = 0;
          for(let brandkey in brands){
            axios.delete(process.env.VUE_APP_HOLODECK_API_URL + "/cast-video/" + brands[brandkey].id)
                .then(() => {
                  if(this.selects.brand.selected.length){
                    delCount++;
                    if(delCount === brands.length){
                      let postCounter = 0;
                      for(let key in newIds){
                        axios.post(process.env.VUE_APP_HOLODECK_API_URL + "/cast-video", {
                          cast_id: newIds[key],
                          castrole_id: process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID,
                          video_id: this.video.id
                        })
                            .then(() => {
                              postCounter++;
                              if(postCounter === newIds.length){
                                this.success.brands = true;
                                this.allSuccessfullCheck();
                              }
                            })
                      }
                    }
                  }else{
                    this.success.brands = true;
                    this.allSuccessfullCheck();
                  }

                })
          }
        }else{
          let postCounter = 0;
          for(let key in newIds){
            axios.post(process.env.VUE_APP_HOLODECK_API_URL + "/cast-video", {
              cast_id: newIds[key],
              castrole_id: process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID,
              video_id: this.video.id
            })
                .then(() => {
                  postCounter++;
                  if(postCounter === newIds.length){
                    this.success.brands = true;
                    this.allSuccessfullCheck();
                  }
                })
          }
        }
      })
    },
    putVote(){
      axios.put(process.env.VUE_APP_HOLODECK_API_URL + "/votes/" + this.vote_id, {
        grade: this.vote,
        video_id: this.video.id,
        user_id: 1
      })
      .then(() => {
        this.success.votes = true;
        this.allSuccessfullCheck();
      })
    },
    putProducts(){
      if(this.hasProductCast){
        axios.delete(this.apis.products + "/" + this.hasProductCast)
        .then(() => {
          if(this.products){
            axios.post(process.env.VUE_APP_HOLODECK_API_URL + "/casts", {
              name: this.products,
              forename: "mercartor"
            })
                .then(response => {
                  let res = this.getfetchedData(response.data);
                  axios.post(this.apis.products, {
                    cast_id: res.id,
                    video_id: this.video.id,
                    castrole_id: process.env.VUE_APP_HOLODECK_API_CASTROLE_MERKARTORPRODUCT_ID
                  })
                      .then(() => {
                        this.success.products = true;
                        this.allSuccessfullCheck();
                      })
                })
          }else{
            this.success.products = true;
            this.allSuccessfullCheck();
          }

        })
      }else if(this.products){
        axios.post(process.env.VUE_APP_HOLODECK_API_URL + "/casts", {
          name: this.products,
          forename: "mercartor"
        })
            .then(response => {
              let res = this.getfetchedData(response.data);
              axios.post(this.apis.products, {
                cast_id: res.id,
                video_id: this.video.id,
                castrole_id: process.env.VUE_APP_HOLODECK_API_CASTROLE_MERKARTORPRODUCT_ID
              })
              .then(() => {
                this.success.products = true;
                this.allSuccessfullCheck();
              })
            })
      }else{
        this.success.products = true;
        this.allSuccessfullCheck();
      }
    },
    putVideoInfo(){
      console.log(this.videoTemp.to_publish_at);
      if(this.videoTemp.to_publish_at && this.videoTemp.to_publish_at[0]){
        this.videoTemp.to_end_at = this.videoTemp.to_publish_at[1];
        this.videoTemp.to_publish_at = this.videoTemp.to_publish_at[0];
      }else{
        this.videoTemp.to_end_at = null;
        this.videoTemp.to_publish_at = null;
      }
      this.videoTemp.producer_id = this.selects.producer.selected.id;
      axios.put(process.env.VUE_APP_HOLODECK_API_URL + "/videos/" + this.requestedVideo, this.videoTemp, {headers: {
          'content-type': 'application/json'
        }})
      .then(() => {
        this.success.video = true;
        this.allSuccessfullCheck();
      })
      .catch(error => {
        console.log(error)
      })
    },
    allSuccessfullCheck(){
      console.log("CHECK");
      console.log(this.success);
      if(this.success.video && this.success.brands && this.success.genres && this.success.products && this.success.votes){
        console.log("RELOAD");
        window.location.reload();
      }
    }
  },
  mounted(){
    this.fetchVideo();
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      console.log('onPlayerReady', this)
    });
  }
}
</script>

<style scoped>
  #edit_header{
    display: flex;
    align-items: center;
    width: 1500px;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #edit_headerButton{
    background-color: var(--color-dark-1);
    color: var(--color-light-0);
    font-size: var(--font-size-12);
  }
  #edit_headerTitle{
    font-size: var(--font-size-14);
  }
  #edit_main{
    display: grid;
    width: 1500px;
    margin: auto;
    grid-template-columns: auto 400px;
    grid-template-rows: auto;
    grid-gap: 10px;
  }
  #edit_mainBox_name{
    display: flex;
  }
  #edit_mainBox_description{
    display: flex;
  }
  #edit_mainBox_producerId{
    display: flex;
  }
  #edit_mainBox_publishedAt{
    display: flex;
  }
  #edit_mainBox_toPublishAt{
    display: flex;
  }
  #edit_mainBox_toEndAt{
    display: flex;
  }
  #edit_mainBox_blacklisted{
    display: flex;
  }
  #edit_mainBox_videoPreview{
    --videoPreview-width: 400px;
    display: flex;
    width: var(--videoPreview-width);
    height: calc(var(--videoPreview-width) * 9 / 16);
  }
  #edit_mainBox_videoBox_videolink,
  #edit_mainBox_videoBox_filename,
  #edit_mainBox_videoBox_uploadedAt,
  #edit_mainBox_videoBox_blacklisted,
  #edit_mainBox_videoBox_vote{
    padding: 10px;
  }
  #edit_mainBox_videoBox_uploadedAt{
    font-size: var(--font-size-10);
  }
  #edit_mainBox_videoPreview_filename{
    font-size: var(--font-size-10);
  }
  #edit_mainBox_videoBox{
    display: flex;
    flex-direction: column;
    width: 400px;
    max-height: 480px;
    background-color: var(--color-light-1);
  }
  #edit_mainDataBox{
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  #edit_mainDataBox input,
  #edit_mainDataBox textarea{
    width: calc(100% - 22px);
    padding: 0;
    margin: 0;
    height: 40px;
    border: 1px solid var(--color-light-2);
    padding-left: 10px;
    padding-right: 10px;
  }
  #edit_mainDataBox textarea{
    height: 150px;
    padding-top: 10px;
  }
  #edit_mainDataBox>.edit-info-label{
    margin-bottom: 20px;
  }
  #edit_main_dates{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  .edit_main_datesPicker {
    background-color: red;
  }
  #edit_headerButton{
    max-height: 40px;
    width: 150px;
  }
  .loading{
    opacity: .5;
    pointer-events: none;
    user-select: none;
  }
</style>
