<template>
  <div v-if="video" :key="video.id" id="watchVideo_main">

    <div id="watchVideo_video">
      <div id="watchVideo_player">
        <video ref="videoPlayer" class="video-js"></video>
      </div>
    </div>
    <div id="watchVideo_secondInfo">
      <div id="watchVideo_secondInfo_channel">
        <img v-if="brandCast && brandCast.links[0]" :src="brandCast.links[0].link" alt="">
        <img v-else src="https://th.bing.com/th/id/R653f93c3cb58cb7f21b6a721ebdbec19?rik=dDXT3D3c%2bSRfNg&riu=http%3a%2f%2fwww.repol.copl.ulaval.ca%2fwp-content%2fuploads%2f2019%2f01%2fdefault-user-icon.jpg&ehk=5SV6bh5JXp3uM9swhors3LcaN1Iskv0xBV%2f7Hnq40s4%3d&risl=&pid=ImgRaw" alt="">
        <span id="watchVideo_secondInfo_channelName" v-if="video.channel">
          <router-link v-if="brandCast" :to="'/channel?c=' + brandCast.id">
            {{ brandCast.name }}
          </router-link>
          <router-link v-if="isEditor" :to="'/edit?v=' + video.id">
            Edit
            <i class="fas fa-edit"></i>
          </router-link>
        </span>
      </div>
      <div id="watchVideo_secondInfo_description">
        <p>{{ video.description }}</p>
      </div>
      <div id="watchVideo_secondInfo_producer">
        <!--<span v-if="video.producer">{{ video.producer.name }}</span>-->
        <span v-for="cast in video.casts" :key="cast.id">
            {{ cast.name }}
        </span>
      </div>
      <div id="watchVideo_secondInfo_shop">
        <watch-shop-box :key="product" v-for="product in getProductsFromCasts(video.casts)" :productnumber="'variant/' + product"></watch-shop-box>
      </div>
    </div>
    <div id="watchVideo_firstInfo">
      <div id="watchVideo_firstInfo_genres">
        <span v-for="genre in video.genres" :key="genre.id">
          <router-link style="text-decoration: none;" :to="'channel?g=' + genre.id">
            #{{ genre.name }}
          </router-link>
        </span>
      </div>
      <span id="watchVideo_firstInfo_title">{{ video.name }}</span>
    </div>
    <div id="watchVideo_related">
      <template v-if="firstGenre && firstGenre.name !== 'LIVE'">
        <home-section bg="white" :videos="brandCast.videos" >Mehr von {{ brandCast.name }}</home-section>
      </template>
      <template v-else-if="firstGenre">
        <home-section bg="white" :videos="firstGenre.videos" >Mehr von {{ firstGenre.name }}</home-section>
      </template>

    </div>

    <!--
    <div id="watchVideo_player">
      <video ref="videoPlayer" class="video-js"></video>
    </div>
    <div id="watchVideo_info">
      <span id="watchVideo_info_title">{{ video.name || 'sd' }}</span>
      <div class="general_hr"></div>
      <div v-if="video.channels.length" id="watchVideo_info_channel">
        <img id="watchVideo_info_channel_logo" :src="video.thumbnails[0].joatlink" alt="">
        <router-link to="/channel" id="watchVideo_info_channel_name">
          {{ video.channels[0].name }}
        </router-link>
      </div>
    </div>
    -->

  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import '@videojs/themes/city/index.css';
import '@videojs/themes/fantasy/index.css';
import '@videojs/themes/sea/index.css';
import '@videojs/themes/forest/index.css';
import videojs from 'video.js';
import axios from "axios";
import HomeSection from "@/components/homeComponents/homeSection";
import WatchShopBox from "@/components/watchComponents/watchShopBox";

export default {
  name: "watchVideo",
  components: {WatchShopBox, HomeSection},
  props: ["video", "startTime"],
  computed: {
    isEditor(){
      return this.$dmAuth.isAuthorizedFor(['editor'])
    }
  },
  data(){
    return{
      firstGenre: null,
      player: null,
      options: {
        autoplay: true,
        controls: true,
        muted: false,
        sources: [{
          src: process.env.VUE_APP_JOAT_KV_API_URL + this.video.joatlink
        }]
      },
      brandCast: null,
    }
  },
  methods: {
    fetchFirstGenre(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/genres/' + this.video.genres[0].id)
      .then(response => {
        this.firstGenre = response.data;
      })
    },
    getProductsFromCasts(casts){
      let product = casts.filter(x => x.forename === "mercartor");
      if(product){
        return product[0] ? product[0].name.split(',') : null
      }
    },
    getBrandsFromCasts(casts){
      let castsWithRoles = casts.filter(x => x.intendedroles.length);
      let brands = castsWithRoles.filter(x => x.intendedroles[0].id == process.env.VUE_APP_HOLODECK_API_CASTROLE_BRAND_ID);
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + "/casts/" + brands[0].id)
      .then(response => {
        this.brandCast = response.data
      })
    }
  },
  mounted(){
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      console.log('onPlayerReady', this);
      this.player.currentTime(this.startTime);
    });
    this.fetchFirstGenre();
    this.getBrandsFromCasts(this.video.casts);
  },
  beforeDestroy() {
    if(this.player){
      this.player.dispose();
    }
  }
}
</script>

<style scoped>
 #watchVideo_main{
   display: grid;
   width: 1500px;
  flex-direction: column;
   font-size: var(--font-size-14);
   font-weight: bold;
   grid-template-areas:
    "video secondInfo"
    "firstInfo secondInfo"
    "related related";
   grid-template-columns: 1000px auto;
   grid-gap: 10px;
   margin-top: 10px;
 }
 #watchVideo_video{
   display: flex;
   grid-area: video;
 }
 #watchVideo_firstInfo{
   display: flex;
   grid-area: firstInfo;
   flex-direction: column;
   width: 100%;
 }
 #watchVideo_secondInfo{
   display: flex;
   grid-area: secondInfo;
   height: 100%;
   width: 100%;
   border-left: 1px solid var(--color-light-1);
   padding-left: 10px;
   flex-direction: column;
 }
 #watchVideo_related{
   display: flex;
   grid-area: related;
   height: 200px;
   width: 100%;
 }
 #watchVideo_player{
   display: flex;
 }
 .video-js{
   height: 563px;
   width: 1000px;
 }
 #watchVideo_secondInfo_channel{
   display: flex;
   width: 100%;
 }
 #watchVideo_secondInfo_channelName{
   color: var(--color-dark-0);
 }
 #watchVideo_secondInfo_channel>img{
    display: flex;
   max-height: 100px;
   margin-right: 10px;
 }
 #watchVideo_secondInfo_channel>span{
   font-size: var(--font-size-12);
   font-weight: normal;
 }
 #watchVideo_secondInfo_description>p{
   font-size: var(--font-size-10);
   font-weight: normal;
 }
 #watchVideo_secondInfo_producer{
   display: flex;
   flex-wrap: wrap;
 }
 #watchVideo_secondInfo_producer>span{
   display: flex;
   font-size: var(--font-size-10);
   font-weight: normal;
   background-color: var(--color-light-1);
   padding: 5px;
   margin-right: 5px;
 }
 #watchVideo_secondInfo_shop{
   margin-top: 10px;
    max-height: 380px;
   overflow-y: auto;
   padding-right: 2px;
 }
 #watchVideo_firstInfo_genres{
   display: flex;
 }
 #watchVideo_firstInfo_genres>span{
   display: flex;
   font-size: var(--font-size-10);
   font-weight: normal;
   text-decoration: none;
 }
 #watchVideo_secondInfo_channelName{
   display: flex;
   justify-content: space-between;
   width: calc(100% - 100px);
 }
</style>
