<template>
  <div id="watchShopBox_main" v-if="product">
    <div id="watchShopBox_image">
      <img :src="baseURL.replace('api/', '') + product.image_path_with_filename" alt="">
    </div>
    <div id="watchShopBox_title">
      <a :href="baseURL.replace('/api/', '') + product.article_link" target="_blank">
        {{ product.pageTitle }}
        <i class="fas fa-external-link-alt"></i>
      </a>

    </div>
    <div id="watchShopBox_price">
      <span v-if="product.variantSinglePrice && !(product.variantSinglePrice == '0,00')">{{ product.variantSinglePrice }}€</span>
      <span id="watchShopBox_price_anfrage" v-else>Preis auf Anfrage</span>
    </div>
    <div id="watchShopBox_description">
      <div v-html="product.fd"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "watchShopBox",
  props: ["productnumber"],
  data(){
    return{
      product: null,
      baseURL: process.env.VUE_APP_SHOP_API_URL
    }
  },
  methods: {
    fetchProduct(){
      axios.get(this.baseURL + this.productnumber, {withCredentials: true})
      .then(response => {this.product = response.data});
    },
    simulateProduct(){
      this.product = {
        image_path_with_filename: "tmp/cached_image_sizes/5aec5691a631a0.38571537.jpg",
        pageTitle: "10 l AdBlue TOP RENNER ANGEBOT",
        variantSinglePrice: "5,90",
        fd: "<div>AdBlue® wird für die Abgasreinigung von Kraftwagen weltweit eingesetzt. Es handelt sich um eine klare, ungiftige Harnstoff-Lösung (AUS 32). Diese kann gefahrlos verwendet werden und ist umweltverträglich. AdBlue® wird in einem getrennten Behälter in der Nähe des Dieseltanks gelagert. <b>Es wird dem Dieselkraftstoff nicht beigemengt.</b></div><div><br></div><div>Die Qualität von AdBlue® (Harnstoff AUS 32) ist für die problemlose Verwendung in Kfz außerordentlich wichtig, daher sind die Anforderungen in den ISO-Normen 22241-1 bis -4 festgelegt.</div><div><br></div><div>ADBlue® ist eine eingetragene Marke des VDA und wird in Lizenz vor allem von Fahrzeugherstellern, Zulieferern und Unternehmen der chemischen Industrie sowie der Mineralölwirtschaft zur Verfügung gestellt.</div><div><br></div><div>Die Marke AdBlue® ist u.a. anwendbar auf Kraftfahrzeuge, Kfz-Teile, in Bedienungsanleitungen von Kfz und für den Harnstoff AUS 32.</div>",
        article_link: "/catalog/detail/1594/"
      }
    }
  },
  mounted(){
    this.fetchProduct();
    //this.simulateProduct();
  }
}
</script>

<style scoped>
  #watchShopBox_main{
    display: grid;
    height: 150px;
    width: 100%;
    background-color: var(--color-light-0);
    border: 1px solid var(--color-light-1);
    margin-bottom: 10px;
    grid-template-areas:  "image title"
                          "image description"
                          "price description";

  }
  #watchShopBox_main>*{

  }
  #watchShopBox_image{
    display: flex;
    grid-area: image;
    height: 120px;
    width: 120px;
    justify-content: center;
    align-items: center;
  }
  #watchShopBox_image>img{
    display: flex;
    max-height: 95%;
    max-width: 95%;
  }
  #watchShopBox_title{
    font-size: var(--font-size-10);
    font-weight: bold;
    grid-area: title;
  }
  #watchShopBox_price{
    display: flex;
    grid-area: price;
    justify-content: flex-end;
    margin-right: 10px;
  }
  #watchShopBox_price_anfrage{
    font-size: var(--font-size-10);
  }
  #watchShopBox_description{
    font-size: var(--font-size-10);
    font-weight: normal;
    grid-area: description;
    overflow-y: scroll;
    color: var(--color-dark-0)
  }
</style>
