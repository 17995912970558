<template>
  <layout-base>
    <div id="home_main">
      <template v-if="genres">
        <home-banner-display v-if="votedVideosReady" :liveVideo="liveVideo" :videos="votedVideos"></home-banner-display>
        <div id="home_sections" v-if="genres.filter(x => x.name !== 'LIVE')">
          <template v-for="genre in genres.filter(x => x.name !== 'LIVE')">
            <img @click="$router.push('/channel?g='+genre.id)" :key="genre.id + 'banner'" class="home_sectionsBanner" :src="getJoatLink(genre, 'smallBanner')" alt="">
            <home-section :key="genre.id"  :videos="genre.videos" :genre="genre" :withBanner="true"></home-section>
            <!--<div :key="genre.id + 'hr'" class="general_hr"></div>-->
          </template>
          <template v-for="genre in genres.filter(x => x.name === 'LIVE')">
            <img v-if="$dmAuth.isAuthorizedFor(['editor'])" @click="$router.push('/channel?g='+genre.id)" :key="genre.id + 'banner'" class="home_sectionsBanner" :src="getJoatLink(genre, 'smallBanner')" alt="">
            <home-section v-if="$dmAuth.isAuthorizedFor(['editor'])"  :key="genre.id"  :videos="genre.videos" :genre="genre" :withBanner="true"></home-section>
            <!--<div :key="genre.id + 'hr'" class="general_hr"></div>-->
          </template>
        </div>
      </template>
    </div>
    <transition name="fade">
      <div :key="1" v-show="!ready" id="home_loadingScreen">
        <img id="topNavigation_leftBox_logo" src="@/assets/pstv.png" alt="">
        <loading-bar></loading-bar>
      </div>
      <div :key="2" v-show="ready"></div>
    </transition>
  </layout-base>
</template>

<script>

import LayoutBase from "@/components/layouts/layoutBase";
import HomeBannerDisplay from "@/components/homeComponents/homeBannerDisplay";
import HomeSection from "@/components/homeComponents/homeSection";

import axios from 'axios';
import LoadingBar from "@/components/generalComponents/loadingBar";

export default {
  name: 'Home',
  components: {LoadingBar, HomeSection, HomeBannerDisplay, LayoutBase},
  data(){
    return{
      genres: null,
      allgenres: null,
      votes: null,
      liveMode: false,
      liveVideo: null,
      votedVideos: [null, null, null],
      votedVideosReady: false,
      vote1s: [],
      vote2s: [],
      vote3s: []
    }
  },
  computed: {
    ready(){
      return (this.genres && this.votes && this.votedVideosReady);
    },
  },
  methods: {
    fetchGenres(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/genres')
        .then(response => {
          this.genres = response.data;
          this.allgenres = response.data;
          // LIVE MODE
          this.testLiveEvent();
        })
    },
    getRandomVideo(videos){
      let videoCount = videos.length;
      if(videoCount === 0){
        return null
      }
      let randomIndex = Math.floor(Math.random() * videoCount);
      let video = null;

      videos[randomIndex].video ? video = videos[randomIndex].video : video = videos[randomIndex];
      if(video.blacklisted){
        videos.splice(randomIndex, 1);
        return this.getRandomVideo(videos);
      }else {
        return video;
      }
    },
    fetchVotes(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/votes')
      .then(response => {
        this.votes = response.data;
        this.vote1s = this.votes.filter(x => x.grade === 1);
        this.vote2s = this.votes.filter(x => x.grade === 2);
        this.vote3s = this.votes.filter(x => x.grade === 3)

        this.vote1s.length ? this.votedVideos[1] = this.getRandomVideo(this.vote1s) : this.votedVideos[1] = this.getRandomVideo(this.votes);
        this.vote2s.length ? this.votedVideos[2] = this.getRandomVideo(this.vote2s) : this.votedVideos[2] = this.getRandomVideo(this.votes);
        this.vote3s.length ? this.votedVideos[0] = this.getRandomVideo(this.vote3s) : this.votedVideos[0] = this.getRandomVideo(this.votes);

        this.votedVideosReady = true;
      })
    },
    getJoatLink(genre, type){
      let thumbnails = genre.thumbnails;
      let right = thumbnails.filter(x => x.joatlink.includes(type))[0];
      return process.env.VUE_APP_JOAT_KV_API_URL + right.joatlink;
    },
    testLiveEvent(){
      let possibleLiveGenre = this.allgenres.filter(x => x.name === 'LIVE');
      if(possibleLiveGenre[0]){
        console.log(possibleLiveGenre[0].videos.filter(x => x.blacklisted === 0));
        console.log('CHECK BLACKLISTED', !possibleLiveGenre[0].videos.filter(x => x.blacklisted === 0)[0]);
        if(!possibleLiveGenre[0].videos.filter(x => x.blacklisted === 0)[0]){
          return
        }
      }
      if(possibleLiveGenre.length){
        this.liveVideo = this.getRandomVideo(possibleLiveGenre[0].videos);
      }
    }
  },
  mounted(){
    this.fetchGenres();
    this.fetchVotes();
  }
}
</script>

<style scoped>
  #home_main{
    display: flex;
    width: 100%;
    padding-bottom: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-dark-0);
  }
  #home_sections{
    display: flex;
    width: var(--layoutBase_content_width);
    flex-direction: column;
  }
  .home_sectionsBanner{
    display: flex;
    height: 70px;
    width: 100%;
    margin-bottom: -30px;
    margin-top: 30px;
    cursor: pointer;
  }
  #home_loadingScreen{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: var(--color-dark-0);
    z-index: 500;
    justify-content: center;
    align-items: center;
  }
  #home_loadingScreen img{
    width: 300px;
    margin-bottom: 10px;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
