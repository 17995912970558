class ChannelCommon {
    constructor() {
        this.name = null;
        this.videos = null;
        this.image = null;
    }
    fromChannel(channel){
        this.image = channel.links.length ? channel.links[0].link : null
        this.name = channel.name;
        this.videos = channel.videos;
        return this;
    }
    fromGenre(genre){
        this.image = genre.thumbnails[2];
        this.name = genre.name;
        this.videos = genre.videos;
        return this;
    }
}
module.exports = ChannelCommon
