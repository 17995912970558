<template>
  <div id="topNavigation_main">
    <router-link id="topNavigation_leftBox" to="/">
      <img id="topNavigation_leftBox_logo" src="@/assets/pstv.png" alt="">
    </router-link>
    <div id="topNavigation_middleBox">
      <main-searchbar></main-searchbar>
      <router-link id="topNavigation_middleBox_lf" to="/lf">
        <i class="fas fa-border-all"></i>
        <span>Aussteller</span>
      </router-link>
    </div>
    <div id="topNavigation_rightBox">
      <div id="topNavigation_info">
        <router-link v-if="$dmAuth.isAuthorizedFor(['editor'])" to="/studio">
          <i class="fas fa-film"></i>
          Studio
        </router-link>
      </div>
      <div id="topNavigation_user">
        <!-- {{ $dmAuth.getIdKey('email') }} -->
      </div>
    </div>

  </div>
</template>

<script>
import MainSearchbar from "@/components/nav/mainSearchbar";
export default {
  name: "topNavigation",
  components: {MainSearchbar}
}
</script>

<style scoped>
  #topNavigation_main{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-dark-0);
    color: var(--color-light-0);
  }
  #topNavigation_leftBox,
  #topNavigation_rightBox{
    display: flex;
    height: 100%;
    width: 200px;
    justify-content: center;
    align-items: center;
  }
  #topNavigation_middleBox{
    display: flex;
    align-items: center;
  }
  #topNavigation_middleBox_lf{
    display: flex;
    text-decoration: none;
    color: var(--color-light-0);
    align-items: center;
    margin-left: 10px;
  }
  #topNavigation_middleBox_lf>span{
    margin-left: 5px;
    text-decoration: underline;
  }
  #topNavigation_leftBox{
    color: var(--color-light-0);
    text-decoration: none;
    font-size: var(--font-size-14);
  }
  #topNavigation_leftBox_logo{
    display: flex;
    max-height: 80%;
  }
  #topNavigation_leftBox>i{
    margin-bottom: -2px;
    margin-right: 5px;
  }
  #topNavigation_info *{
    text-decoration: none;
    color: var(--color-light-0);
  }
</style>
