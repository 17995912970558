import querystring from 'query-string'
import crypto from 'crypto-js';
import strings from 'locutus/php/strings';
import axios from 'axios';


export default function (Vue) {
        //let authenticatedUser = {};

    Vue.dmAuth = {

        authenticatedUser: {},

        parseJwt: function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        getIdKey: function(key) {
            var token = localStorage.getItem('id_token');
            let ret = this.parseJwt(token)
            return ret[key]
        },

        redirect: function() {
            this.setOAuthStrings();
            let data = new Object();
            data = {
                'client_id': process.env.VUE_APP_CUSAFE_ID,
                'redirect_uri' : process.env.VUE_APP_APP_PATH + 'callback',
                'response_type' : 'code',
                'scope' : 'openid roles address email',
                'state' : this.getState(),
                'code_challenge' : this.getCodeChallenge(),
                'code_challenge_method' : 'S256',

            }
            let query=querystring.stringify(data)
            window.location = process.env.VUE_APP_CUSAFE_PATH + 'authorize?' + query;
        },

        makeApiCall: function(payload) {
            return axios.post(process.env.VUE_APP_CUSAFE_PATH + 'token',payload)
        },
        getTokenData: function(grant_type,code) {
            let data = {
                'grant_type' : grant_type,
                'client_id' : process.env.VUE_APP_CUSAFE_ID,
                'redirect_uri' : process.env.VUE_APP_APP_PATH + 'callback',
                'code_verifier' : this.getCodeVerifier(),
                'code' : code,
            }

            return data
        },
        callback:  async function(code) {

            let data = this.getTokenData('authorization_code',code)
            try {
                var response = await this.makeApiCall(data)

                this.setTokens(response.data)
            }
            catch(error) {
                return false
            }


            return true;
        },

        setTokens: function(tokens) {

            localStorage.setItem('access_token', tokens.access_token)
            localStorage.setItem('refresh_token', tokens.refresh_token)
            localStorage.setItem('expires_in', tokens.expires_in)
            localStorage.setItem('id_token', tokens.id_token)

        },
        getToken: function() {

            var token = localStorage.getItem('access_token');
            var expiration = localStorage.getItem('expires_in');
            if (!token || !expiration) {

                return null;
            }
            /*
            if(Date.now() > parseInt(expiration)) {
                this.killTokens();
                return null
            }
            */
            return token;
        },
        killTokens: function() {

            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('expires_in')
            localStorage.removeItem('id_token')

        },
        isAuthenticated: function() {

            if (this.getToken()) {
                return true;
            } else {
                return false;
            }

        },
        setAuthenticatedUser: function(data) {
            this.authenticatedUser=data;
            return this;
        },
        getAuthenticatedUser: function() {
            return this.authenticatedUser;
        },
        generateRandomString: function(length=6){
            const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let array = new Uint8Array(length);
            window.crypto.getRandomValues(array);
            array = array.map(x => validChars.charCodeAt(x % validChars.length));
            const randomState = String.fromCharCode.apply(null, array);
            return randomState
        },
        setOAuthStrings: function() {
            let state = this.generateRandomString(40)
            let code_verifier = this.generateRandomString(128)
            localStorage.setItem('code_verifier', code_verifier)
            localStorage.setItem('state', state)
        },
        getState: function() {
            return localStorage.getItem('state');
        },
        getCodeVerifier: function() {
            return localStorage.getItem('code_verifier');
        },
        getCodeChallenge: function() {
            let code_verifier = this.getCodeVerifier()
            //alert(code_verifier)
            let base64hash =crypto.SHA256(code_verifier).toString(crypto.enc.Base64)
            //alert(base64hash)
            let retval = strings.strtr(strings.rtrim(base64hash,'='),'+/', '-_')
            //alert (retval)
            return retval
        },
        isAuthorizedFor(permissions) {

            //console.log(permission)
            //console.log()
            let permitted = false;
            for(let key of permissions){
                if(this.getIdKey('roles')[process.env.VUE_APP_CUSAFE_ID] == key){
                    permitted = true
                    break
                }
            }
            return permitted



        }


    }
    Object.defineProperties(Vue.prototype, {

        $dmAuth: {
            get: function() {
                return Vue.dmAuth;
            }
        }

    });
}
