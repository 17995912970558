import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Channel from '../views/Channel.vue'
import Watch from '../views/Watch.vue'
import Edit from '../views/Edit.vue'
import Upload from '../views/Upload.vue'
import Lf from '../views/Lf.vue'
import NotFound from '../views/404.vue'
import callback from '../components/generalComponents/Callback.vue'
import Studio from "@/views/Studio.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      roles: ['public', 'editor'],
      needsAuth: true
    }
  },
  {
    path: '/channel',
    name: 'Channel',
    component: Channel,
    meta: {
      roles: ['public', 'editor'],
      needsAuth: true
    }
  },
  {
    path: '/watch',
    name: 'Watch',
    component: Watch,
    meta: {
      roles: ['public', 'editor'],
      needsAuth: true
    }
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit,
    meta: {
      roles: ['editor'],
      needsAuth: true
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    meta: {
      roles: ['editor'],
      needsAuth: true
    }
  },
  {
    path: '/studio',
    name: 'Studio',
    component: Studio,
    meta: {
      roles: ['editor'],
      needsAuth: true
    }
  },
  {
    path: '/lf',
    name: 'Lf',
    component: Lf,
    meta: {
      roles: ['public', 'editor'],
      needsAuth: true
    }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: callback,
    meta: {
      roles: ['public', 'editor'],
      needsAuth: false
    }
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: NotFound,
    meta: {
      needsAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
