<template>
  <div id="videoPreview_main">
    <video id="videoPreview_video" ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "videoPreview",
  props: ['video', 'newOptions', 'startTime'],
  data(){
    return{
      player: null,
      options: {
        autoplay: true,
        controls: true,
        muted: true,
        loop: true,
        sources: [{
          src: process.env.VUE_APP_JOAT_KV_API_URL + this.video.joatlink
        }]
      },
    }
  },
  methods: {

  },
  mounted(){
    this.player = videojs(this.$refs.videoPlayer, this.newOptions || this.options, () => {

      if(this.startTime){
        this.player.currentTime(this.startTime)
      }
    });
  },
  beforeDestroy() {
    if(this.player){
      this.player.dispose();
    }
  }
}
</script>

<style scoped>
  #videoPreview_video{
    display: flex;
    height: 100%;
    width: 100%;
  }
</style>
