<template>
  <layout-base>
    <div id="channel_main">
      <template v-if="channel">
        <div id="channel_banner">
          <div id="channel_logoBox">
            <img v-if="$route.query['c']" :src="channel.image" alt="">
            <div id="channel_bannerName">{{ channel.name }}</div>
          </div>
          <img v-if="$route.query['g']" id="channel_bannerBackground_image_genre" :src="joatApiUrl + channel.image.joatlink">
          <img v-else id="channel_bannerBackground_image" :src="joatApiUrl + channel.videos[0].thumbnails[0].joatlink">
        </div>
        <div id="channel_videos">
          <template v-for="video in channel.videos">
            <video-display v-if="!video.blacklisted"  mode="section" :video="video" :key="video.id" ></video-display>
          </template>

        </div>
      </template>
    </div>
  </layout-base>
</template>

<script>
import ChannelCommon from "@/assets/scripts/classes/channelCommon";
import LayoutBase from "@/components/layouts/layoutBase";
import axios from "axios";
import VideoDisplay from "@/components/generalComponents/videoDisplay";
export default {
  name: "Channel",
  components: {VideoDisplay, LayoutBase},
  data(){
    return{
      channel: null,
      joatApiUrl: process.env.VUE_APP_JOAT_KV_API_URL
    }
  },
  methods: {
    fetchChannel(){
      let route = null;
      if(this.$route.query['c']){
        route = '/casts/' + this.$route.query['c'];
      }else if(this.$route.query['g']){
        route = '/genres/' + this.$route.query['g']
      }
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + route)
        .then(response => {
          let channelObj = new ChannelCommon();
          if(this.$route.query['c']){
            this.channel = channelObj.fromChannel(response.data);
          }else if(this.$route.query['g']){
            this.channel = channelObj.fromGenre(response.data);
          }
        })
    }
  },
  mounted(){
    this.fetchChannel();
  }
}
</script>

<style scoped>
  html{
    background-color: var(--color-dark-0)!important;
    height: 100%!important;
  }
  #channel_main{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-dark-0);
  }
  #channel_banner{
    display: flex;
    height: 250px;
    background-color: red;
    width: 100%;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: flex-end;
  }
  #channel_bannerBackground_image{
    display: flex;
    position: absolute;
    top: 0;
    left: -5%;
    width: 110%;
    transform: translateY(-50%);
    -webkit-filter: blur(30px);
    filter: blur(30px);
    z-index: 10;
  }
  #channel_bannerBackground_image_genre{
    display: flex;
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 10;
  }
  #channel_logoBox{
    display: flex;
    width: 1500px;
    z-index: 20;
    flex-direction: column;
  }
  #channel_logoBox img{
    display: block;
    max-width: 200px;
  }
  #channel_bannerLogo{

  }
  #channel_bannerName{
    font-size: var(--font-size-18);
    color: var(--color-light-0)
  }
  #channel_banner_logo{
    display: flex;
    z-index: 100;
  }
  #channel_videos{
    display: grid;
    margin-top: 20px;
    max-width: 1500px;
    padding-bottom: 100%;
    grid-template-columns: repeat(auto-fill, 210px);
  }
</style>
