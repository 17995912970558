<template>
  <layout-base>
    <div id="studio_main">
      <div id="studio_main_uploader">
        <uploader v-on:fileSelect="NEWvideo = $event; tryUpload()" accept="video/*">Video</uploader>
        <uploader v-on:fileSelect="NEWthumbnail = $event; tryUpload()" accept="image/*">Thumbnail</uploader>
      </div>
      <div id="studio_main_videoList">
        <div>
          <input type="text" v-model="searchTerm" placeholder="Search..." style="min-width: 400px;">
          <input type="date" v-model="searchDate" style="float: right;">
          <button @click="searchDate = 'none'" style="float: right;">reset</button>
        </div>
        <div>
          only Blacklisted
          <input type="checkbox" v-model="onlyBlacklisted">
        </div>
        <table v-if="videos">
          <thead>
          <tr>
            <th style="text-align: left;">Videotitel</th>
            <th style="text-align: right;">Sichtbarkeit</th>
          </tr>
          </thead>
          <tbody>
          <tr @click="$router.push('/edit?v=' + video.id)" v-for="video in processedVideos" :key="video.id">
            <td>{{ video.name }}</td>
            <td style="text-align: right;">
              <i v-if="video.blacklisted" class="fas fa-eye-slash" style="color: orangered;"></i>
              <i v-else class="fas fa-eye" style="color: cornflowerblue;"></i>
            </td>
          </tr>
          </tbody>
        </table>
        <loading :black="true" v-else></loading>
      </div>
      <div id="studio_main_uploading" v-if="uploading">
        <loading :black="true"></loading>
      </div>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/layouts/layoutBase";
import axios from "axios";
import Loading from "@/components/generalComponents/loading";
import Uploader from "@/components/studioComponents/uploader";
export default {
name: "Studio",
  components: {Uploader, Loading, LayoutBase},
  data(){
    return{
      videos: null,
      searchTerm: '',
      searchDate: 'none',
      onlyBlacklisted: true,
      uploading: false,
      NEWvideo: null,
      NEWthumbnail: null,
      uploadCheckCounter: 0,
      hc: 0,
    }
  },
  computed: {
    processedVideos(){
      let output;
      output = this.videos.filter(x => x.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      if(this.searchDate !== "none"){
        let selectedDate = new Date(this.searchDate).toLocaleDateString();
        output = output.filter(x => {
          let videoDate = new Date(x.to_publish_at).toLocaleDateString();
          return videoDate == selectedDate;
        });
      }

      if(this.onlyBlacklisted){
        output = output.filter(x => x.blacklisted == 1);
      }
      return output.reverse();
    }
  },
  methods: {
    fetchVideos(){
      axios.get(process.env.VUE_APP_HOLODECK_API_URL + '/videos')
      .then(response => {
        this.videos = response.data
      })
    },
   isValidDate(year, month, day) {
      month = month - 1;
      var d = new Date(year, month, day);
      if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
        return true;
      }
      return false;
    },
    tryUpload(){
      if(this.NEWthumbnail && this.NEWvideo){
        this.upload();
      }
    },
    upload(){
      console.info('UPLOAD');
      this.uploading = true;
      this.uploadFile(this.NEWvideo, process.env.VUE_APP_JOAT_VIDEO_PATH);
      this.uploadFile(this.NEWthumbnail, process.env.VUE_APP_JOAT_IMAGE_PATH);

    },
    parseFileName(name){
      let output = name;
      let idx = output.lastIndexOf('.');
      let ext = (idx < 1) ? "" : output.substr(idx + 1);
      output = output.replace(ext, '');
      output = output.replace(/[^a-z0-9]/gi,'');
      output += '.' + ext;

      return output;
    },
    uploadFile(file, path){
      let formData = new FormData();
      formData.append('file', file, this.parseFileName(file.name));
      console.log(formData);
      axios.post(process.env.VUE_APP_JOAT_KV_API_URL + process.env.VUE_APP_JOAT_BUCKET + '/files?path=' + path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': process.env.VUE_APP_JOAT_KEY
        }
      }).then(() => {
        this.tryUploadInfo();
      }).catch(() => {

      })
    },
    tryUploadInfo(){
      this.uploadCheckCounter++
      if(this.uploadCheckCounter === 2){
        this.uploadInfo();
      }
    },
    uploadInfo(){
      axios.post(process.env.VUE_APP_HOLODECK_API_URL + '/videos', {
        name: this.parseFileName(this.NEWvideo.name),
        joatlink: process.env.VUE_APP_JOAT_BUCKET + '/files?path=' + process.env.VUE_APP_JOAT_VIDEO_PATH + '/' + this.parseFileName(this.NEWvideo.name),
        description: this.parseFileName(this.NEWvideo.name),
        uploaded_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        channel_id: 1,
        uploader_id: 1,
        country_id: 1,
        language_id: 1,
        blacklisted: 1,
      })
      .then(response => {
        this.uploadVote(response.data.id);
        this.uploadThumbnail(response.data.id);
      })
    },
    uploadVote(videoId){
      axios.post(process.env.VUE_APP_HOLODECK_API_URL + '/votes', {
        grade: 0,
        video_id: videoId,
        user_id: 1
      })
      .then(() => {
        this.holodeckCheck();
      })
    },
    uploadThumbnail(videoId){
      axios.post(process.env.VUE_APP_HOLODECK_API_URL + '/thumbnails', {
        name: this.parseFileName(this.NEWthumbnail.name),
        description: this.parseFileName(this.NEWthumbnail.name),
        joatlink: process.env.VUE_APP_JOAT_BUCKET + '/files?path=' + process.env.VUE_APP_JOAT_IMAGE_PATH + '/' + this.parseFileName(this.NEWthumbnail.name)
      })
      .then(response => {
        this.uploadThumbnailVideo(response.data.id, videoId)
      })
    },
    uploadThumbnailVideo(thumbnailId, videoId){
      axios.post(process.env.VUE_APP_HOLODECK_API_URL + '/thumbnail-video', {
        thumbnail_id: thumbnailId,
        video_id: videoId
      })
      .then(() => {
        this.holodeckCheck()
      })
    },
    holodeckCheck(){
      this.hc++;
      if(this.hc === 2){
        window.location.reload();
      }
    }
  },
  mounted() {
    this.fetchVideos();
  }
}
</script>

<style scoped>
  #studio_main{
    display: grid;
    width: 1500px;
    min-height: 100px;
    grid-template-columns: 400px auto;
    padding-top: 10px;
  }
  #studio_main_videoList{
    display: flex;
    flex-direction: column;
  }
  #studio_main_videoList table{
    border-collapse: collapse;
    margin-top: 20px;
  }
  #studio_main_videoList table tr{
    cursor: pointer;
  }
  #studio_main_videoList table tr:hover td{
    background-color: var(--color-light-1);
  }
  #studio_main_videoList table tr td{
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: underline;
  }
  #studio_main_uploader{
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
  }
  #studio_main_uploading{
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-light-0);
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>
