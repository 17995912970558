<template>
    <div class="circle pulse orange"></div>
</template>

<script>
export default {
  name: "circlePulse"
}
</script>

<style scoped>
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  background-color: red;
  margin-right: 8px;
}

.pulse {
  animation: pulse-animation 4s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.6);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
}


.master{
  color:black;
  float:right;
}

@media (max-width:900px){
  .master{
    display:none
  }
}
</style>
