<template>
  <div id="uploader_main" @drop.prevent="addFileThroughDrop" @dragover.prevent>
    <slot></slot>
    <input @change="addFileThroughInput" type="file" :accept="accept">
    <span style="color: red">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "uploader",
  props: ['accept'],
  data(){
    return{
      file: null,
      error: null,
      formats: ["aac", "mp4", "f4a", "f4v", "hls", "m3u", "m4v", "mov", "mp3", "mpeg", "oga", "ogg", "ogv", "vorbis", "webm", "jpeg", "jpg", "png"],
      types: ["video/mp4", "video/webm", "video/quicktime", "image/jpeg", "image/png"]
    }
  },
  methods: {
    addFileThroughInput(e){
      let inputFile = e.target.files[0];
      this.validateFile(inputFile)
    },
    addFileThroughDrop(e){
      let droppedFile = e.dataTransfer.files[0];
      console.info(droppedFile);
      //this.validateFile(droppedFile)
    },
    validateFile(file){
      this.error = null;
      if(this.types.includes(file.type)){
        this.file = file;
        this.$emit('fileSelect', file);
      }else{
        this.error = "Not supported File Format.";
      }
    }
  }
}
</script>

<style scoped>
  #uploader_main{
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--color-light-1);
    border: 1px dashed var(--color-light-2);
    margin-bottom: 10px;
  }
  #uploader_main span{
    font-size: var(--font-size-10);
  }
</style>
