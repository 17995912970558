import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dmAuth from './assets/scripts/DmAuth.js'

import '@/assets/styles/general.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

Vue.use(dmAuth)

router.beforeEach(function(to, from, next){

  if(from.name=='Callback' ) {

    console.log('FROM');
    next()

  }
  if(to.matched.some(record => record.meta.needsAuth==false)) {
    if (Vue.dmAuth.isAuthenticated()) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.needsAuth)) {

    if (! Vue.dmAuth.isAuthenticated()) {
      Vue.dmAuth.redirect();
    } else {
      if(Vue.dmAuth.isAuthorizedFor(to.meta.roles)) {
        next()
      } else {
        next({path: "/notfound"})
      }
    }
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
