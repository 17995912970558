<template>
  <div id="infoLabel_main">
    <div id="infoLabel_head">
      {{ label }}
    </div>
    <div id="infoLabel_body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "infoLabel",
  props: ['label']
}
</script>

<style scoped>
  #infoLabel_main{
    display: flex;
    flex-direction: column;
  }
  #infoLabel_head{
    display: flex;
    color: var(--color-light-2);
    font-size: var(--font-size-10);
  }
  #infoLabel_body{

  }
</style>
